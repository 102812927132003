html,
body,
main {
	width: 100%;
	height: 100%;
}

main {
	display: flex;
	align-items: center;
}

section {
	margin: 0 auto;
}

.featured {
	font-size: 1.1em;
}

.btngroup {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.content {
	justify-content: center;
	height: 100%;
	text-align: center;
}

.content img {
	width: 18em;
	display: block;
	margin: 0 auto 1em auto;
}