html, body, main {
  width: 100%;
  height: 100%;
}

main {
  align-items: center;
  display: flex;
}

section {
  margin: 0 auto;
}

.featured {
  font-size: 1.1em;
}

.btngroup {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.content {
  text-align: center;
  justify-content: center;
  height: 100%;
}

.content img {
  width: 18em;
  margin: 0 auto 1em;
  display: block;
}
/*# sourceMappingURL=index.99e5d447.css.map */
